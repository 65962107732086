
var topScroll = {
  topBtn : $('.js-scroll'),
  init: function(){
    this.onEvent();
  },
  onEvent: function(){
    var _this = this;
    $(window).scroll(function(){
      if($(this).scrollTop() > 80){
        _this.topBtn.fadeOut(1000);
      }else{
        _this.topBtn.fadeIn(1000);
      }
    });
  }
};
