// loading

var loading = {
  loading : '.l-loading',
  init: function() {
    this.event();
    // if($('.s-top').length > 0){
    //   this.event();
    // } else {
    //   this.eventSec();
    // }
  },
  event: function(){
    var _this =this;

    TweenMax.to('.l-loading__logo', 3, {
      opacity: 1,
      delay: 1
    });
    TweenMax.to('.l-loading__bg', 2, {
      y: "-120%",
      delay: 3
    });
    TweenMax.to('.l-loading__logo', 1, {
      opacity: 0,
      delay: 3
    });


    setInterval(function(){
      $('body').addClass('start');
    },4000);
    setInterval(function(){
      $(_this.loading).fadeOut(200);
      $('body').removeClass('loading');
      $(window).trigger('loading');
    },4500);

  }
}




