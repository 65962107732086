
var protocolLink = {
  btn : '.js-tel-protocol-link',
  init: function(){
    var _this = this;
    $(_this.btn).each(function (i, el) {
      if (isMobile()) {
        var $el = $(el);
        var tel = $el.data('tel');
        $el.prop('href', 'tel:' + tel);
      }
    });
  }
};
