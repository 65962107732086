
var pageTop = {
  btn : '.js-pagetop',
  ctt : '.pagetop',
  footer : '.l-footer',
  // chat : '.p-chat',
  init: function(){
    var _this = this;
    $(this.btn).on('click', function(){
      _this.onClickEvent();
    });
    // _this.onScroll();
    // _this.onEvent();
  },
  onClickEvent: function(){
    $('html,body').animate({scrollTop: 0}, 1000, 'swing');
  },
  onScroll: function(){
    var _this = this;
    $(window).scroll(function(){
      if($(this).scrollTop() > 200){
        $(_this.ctt).fadeIn(500);
      }else{
        $(_this.ctt).fadeOut(500);
      }
    });
  },
  onEvent: function(){
    var _this = this;
    var position = $(this.footer).offset().top - 200;
    var fixArea = position - $(window).innerHeight();
    $(window).on('scroll', function(){
      var scroll = $(window).scrollTop();
      if(scroll < fixArea){
        $(_this.btn).fadeIn();
      } else {
        $(_this.btn).fadeOut();
      }
    });
  }
}
