
//アンカーリンク
var anchorLink = {
  init: function(){
    this.anchorScroll();
    this.same();
  },
  anchorScroll :function () {
    var q = location.search;
    var m=q.match(/id=([a-zA-Z0-9_]+)/);
    if (m && 0 < m .length){
      var id = m[1];
      var $target = $('#' + id);
      if(0 < $target.length){
        $("html, body").scrollTop(0);
        setTimeout(function () {
          if(isMobile()){
            // var pos = $target.offset().top - $('header').height();
            var pos = $target.offset().top - 0;
          } else {
            var pos = $target.offset().top - 0;
          }
          $("html, body").animate({scrollTop:pos}, 500).promise().done(function() {

              const q = location.search;
              const m = q.match(/member=([a-zA-Z0-9_]+)/);
              if (m && 0 < m .length) {
                const $obj = $('[data-member="'+m[1]+'"]');
                if ($obj) {

                  $obj.trigger('click');
                 }
              }
          });
        }, 300)
      }
    }
  },
  same:function() {
    $('[href*="id="]').each(function(i, e) {
      var $e = $(e);
      var el, s =  $e.prop("href").match(/id=([a-zA-Z0-9_]+)/);
      if (s && s.length == 2) {
        var $target = $('#' + s[1]);
        if ($target) {
          $e.on('click', function() {
            history.pushState(null, "", "?id="+s[1]);
            if(isMobile()){
              var pos = $target.offset().top - 0;
            } else {
              var pos = $target.offset().top - 0;
            }
            $("html, body").animate({scrollTop:pos}, 500);
            return false;
          });
        }
      }
    });
  },
  changeUrl:function(id) {
      history.pushState(null, "", "?id=" + id);
  },
  // same:function() {
  //   $('[href*="id="]').each(function(i, e) {
  //     var $e = $(e);
  //     var el, s =  $e.prop("href").match(/id=([a-zA-Z0-9_]+)/);
  //     if (s && s.length == 2) {
  //       var $target = $('#' + s[1]);
  //       if (0 < $target.length) {
  //         $e.on('click', function() {
  //           if(isMobile()){
  //             let offset =$target.data('anchor-offset-mb') || 70;
  //             var pos = $target.offset().top - offset;
  //           } else {
  //             let offset =$target.data('anchor-offset') || 120;
  //             var pos = $target.offset().top - offset;
  //           };
  //           $("html, body").animate({scrollTop:pos}, 500);
  //           return false;
  //         });
  //       }
  //     }
  //   });
  // },
  onEvent: function(hash){
  }
}
