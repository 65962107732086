//inview

var setInview = {
  init: function(){
    var _this = this;
    $(window).on('scroll.inview', function(){
      //inviewが無くなったら止める
      if (!_this.onEvent()) {
        $(this).off('scroll.inview');
      }
    });
  },
  onEvent: function(){
    var $obj = $(
      '.js-inview:not(.inview),.p-mainVs-pix,.p-mainVs-simple,.p-mainVs-arricl');
    if (!$obj.length) {
      return false
    }
    $obj.each(function(i,el){
      var scrollTop = $(window).scrollTop();
      var outerHeight = $(window).outerHeight();
      var triggerPosition = $(el).offset().top - outerHeight + 0;
      if(scrollTop > triggerPosition){
        $(el).trigger('inview');
        $(el).addClass('inview');
      }
    });
    return true;
  }
}

var onInview = {
  item : $('.js-inview-each'),
  init: function(){
    var _this = this;
    this.onSetting();
    $(this.item).on('inview', function() {
      // _this.inviewEach(this);
    });
  },
  onSetting: function(){
    var _this = this;
    $(this.item).children().addBack().contents().each(function(){
      if (this.nodeType == 3) {
         var $this = $(this);
         $this.replaceWith($this.text().replace(/(\S)/g, "<span>$&</span>"));
      }
    });
  },
  inviewEach: function(el){
    if($(el).find('span').length){
      $(el).find('span').each(function(i, el){
        $(el).delay(40 * i).velocity({
          translateY:"+=70px",
          translateX:"+=15px",
          opacity: 1
        },500);
      });
    }
  }
};


