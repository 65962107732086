// loading

var loading = {
  loading : '.l-loading',
  init: function() {
    this.event();
    // if($('.s-top').length > 0){
    //   this.event();
    // } else {
    //   this.eventSec();
    // }
  },
  event: function(){
    var _this =this;

    TweenMax.to('.l-loading__logo', 3, {
      opacity: 1,
      delay: 1
    });
    TweenMax.to('.l-loading__bg', 2, {
      y: "-120%",
      delay: 3
    });
    TweenMax.to('.l-loading__logo', 1, {
      opacity: 0,
      delay: 3
    });


    setInterval(function(){
      $('body').addClass('start');
    },4000);
    setInterval(function(){
      $(_this.loading).fadeOut(200);
      $('body').removeClass('loading');
      $(window).trigger('loading');
    },4500);

  }
}





// globals
//inview

var setInview = {
  init: function(){
    var _this = this;
    $(window).on('scroll.inview', function(){
      //inviewが無くなったら止める
      if (!_this.onEvent()) {
        $(this).off('scroll.inview');
      }
    });
  },
  onEvent: function(){
    var $obj = $(
      '.js-inview:not(.inview),.p-mainVs-pix,.p-mainVs-simple,.p-mainVs-arricl');
    if (!$obj.length) {
      return false
    }
    $obj.each(function(i,el){
      var scrollTop = $(window).scrollTop();
      var outerHeight = $(window).outerHeight();
      var triggerPosition = $(el).offset().top - outerHeight + 0;
      if(scrollTop > triggerPosition){
        $(el).trigger('inview');
        $(el).addClass('inview');
      }
    });
    return true;
  }
}

var onInview = {
  item : $('.js-inview-each'),
  init: function(){
    var _this = this;
    this.onSetting();
    $(this.item).on('inview', function() {
      // _this.inviewEach(this);
    });
  },
  onSetting: function(){
    var _this = this;
    $(this.item).children().addBack().contents().each(function(){
      if (this.nodeType == 3) {
         var $this = $(this);
         $this.replaceWith($this.text().replace(/(\S)/g, "<span>$&</span>"));
      }
    });
  },
  inviewEach: function(el){
    if($(el).find('span').length){
      $(el).find('span').each(function(i, el){
        $(el).delay(40 * i).velocity({
          translateY:"+=70px",
          translateX:"+=15px",
          opacity: 1
        },500);
      });
    }
  }
};




var pageTop = {
  btn : '.js-pagetop',
  ctt : '.pagetop',
  footer : '.l-footer',
  // chat : '.p-chat',
  init: function(){
    var _this = this;
    $(this.btn).on('click', function(){
      _this.onClickEvent();
    });
    // _this.onScroll();
    // _this.onEvent();
  },
  onClickEvent: function(){
    $('html,body').animate({scrollTop: 0}, 1000, 'swing');
  },
  onScroll: function(){
    var _this = this;
    $(window).scroll(function(){
      if($(this).scrollTop() > 200){
        $(_this.ctt).fadeIn(500);
      }else{
        $(_this.ctt).fadeOut(500);
      }
    });
  },
  onEvent: function(){
    var _this = this;
    var position = $(this.footer).offset().top - 200;
    var fixArea = position - $(window).innerHeight();
    $(window).on('scroll', function(){
      var scroll = $(window).scrollTop();
      if(scroll < fixArea){
        $(_this.btn).fadeIn();
      } else {
        $(_this.btn).fadeOut();
      }
    });
  }
}


//アンカーリンク
var anchorLink = {
  init: function(){
    this.anchorScroll();
    this.same();
  },
  anchorScroll :function () {
    var q = location.search;
    var m=q.match(/id=([a-zA-Z0-9_]+)/);
    if (m && 0 < m .length){
      var id = m[1];
      var $target = $('#' + id);
      if(0 < $target.length){
        $("html, body").scrollTop(0);
        setTimeout(function () {
          if(isMobile()){
            // var pos = $target.offset().top - $('header').height();
            var pos = $target.offset().top - 0;
          } else {
            var pos = $target.offset().top - 0;
          }
          $("html, body").animate({scrollTop:pos}, 500).promise().done(function() {

              const q = location.search;
              const m = q.match(/member=([a-zA-Z0-9_]+)/);
              if (m && 0 < m .length) {
                const $obj = $('[data-member="'+m[1]+'"]');
                if ($obj) {

                  $obj.trigger('click');
                 }
              }
          });
        }, 300)
      }
    }
  },
  same:function() {
    $('[href*="id="]').each(function(i, e) {
      var $e = $(e);
      var el, s =  $e.prop("href").match(/id=([a-zA-Z0-9_]+)/);
      if (s && s.length == 2) {
        var $target = $('#' + s[1]);
        if ($target) {
          $e.on('click', function() {
            history.pushState(null, "", "?id="+s[1]);
            if(isMobile()){
              var pos = $target.offset().top - 0;
            } else {
              var pos = $target.offset().top - 0;
            }
            $("html, body").animate({scrollTop:pos}, 500);
            return false;
          });
        }
      }
    });
  },
  changeUrl:function(id) {
      history.pushState(null, "", "?id=" + id);
  },
  // same:function() {
  //   $('[href*="id="]').each(function(i, e) {
  //     var $e = $(e);
  //     var el, s =  $e.prop("href").match(/id=([a-zA-Z0-9_]+)/);
  //     if (s && s.length == 2) {
  //       var $target = $('#' + s[1]);
  //       if (0 < $target.length) {
  //         $e.on('click', function() {
  //           if(isMobile()){
  //             let offset =$target.data('anchor-offset-mb') || 70;
  //             var pos = $target.offset().top - offset;
  //           } else {
  //             let offset =$target.data('anchor-offset') || 120;
  //             var pos = $target.offset().top - offset;
  //           };
  //           $("html, body").animate({scrollTop:pos}, 500);
  //           return false;
  //         });
  //       }
  //     }
  //   });
  // },
  onEvent: function(hash){
  }
}


var protocolLink = {
  btn : '.js-tel-protocol-link',
  init: function(){
    var _this = this;
    $(_this.btn).each(function (i, el) {
      if (isMobile()) {
        var $el = $(el);
        var tel = $el.data('tel');
        $el.prop('href', 'tel:' + tel);
      }
    });
  }
};


var topScroll = {
  topBtn : $('.js-scroll'),
  init: function(){
    this.onEvent();
  },
  onEvent: function(){
    var _this = this;
    $(window).scroll(function(){
      if($(this).scrollTop() > 80){
        _this.topBtn.fadeOut(1000);
      }else{
        _this.topBtn.fadeIn(1000);
      }
    });
  }
};



(() => {

})();

//DOMContentLoaded
$(() => {
  loading.init();
});

//images resources loaded
$(window).on('load', () => {
  protocolLink.init();
  setTimeout(function() {
    anchorLink.init();
  }, 200);
});

//after loading animation
$(window).on('loading', () => {
  onInview.init();
  setInview.init();
  // topScroll.init();
  pageTop.init();
  $(window).trigger('scroll');
  // setInterval(function(){
  //   $('.decos').toggleClass('active');
  // },8000);
});

// スマホ・タブレットの向き判定
// $(window).on('orientationchange', ()=>{
//   if(isPortrait()){
//     console.log('isPortrait');
//   } else {
//     console.log('isLandscape');
//   }
// });
